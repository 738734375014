import React, { useState, useEffect } from 'react'
import http from '../_api/http'
import ModalVideo from 'react-modal-video'
import team1 from '../assets/images/Educator/LethaNair.png'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const YoutubeVideo = () => {
  const [loading, setLoading] = useState(true)

  const [youtube, setYoutube] = useState([])

  useEffect(() => {
    http.get('api/youtube/ApiYoutubeList').then(res => {
      if (res.data.status === 200) {
        setYoutube(res.data.youtube)
        setLoading(false)
      }
    })
  }, [])

  return (
    <>
      <section className='row gallery-one dup'>
        <div className='container'>
          <div className='row'>
            {loading ? (
              <h4>View YouTube Video Loading...</h4>
            ) : (
              <>
                {youtube.map((item, i) => (
                  <div className='col-md-4' key={i}>
                    <div className='card'>
                      <div className='card-block p-3'>
                        <div className='embed-responsive embed-responsive-16by9'>
                          <iframe
                            className='embed-responsive-item'
                            src={
                              `https://www.youtube.com/embed/` + item.linkname
                            }
                            allowFullScreen
                          />
                          
                        </div>
                        <label className="videoLabel">{item.name}</label>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default YoutubeVideo
